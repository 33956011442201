
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="row">
      <div class="col-md-12">
        <a class="btn btn-danger float-right" href="/apps/createCustomerSegment">Add New</a>
      </div>
    </div>

    <br/>
    <b-table striped hover :items="segments" :fields="columns">
      <template v-slot:cell(totalCustomers)="data">
        <a style="text-decoration: underline; font-weight: bolder;" :href="'/apps/segmentCustomers/'+data.item.id">{{ data.value }}</a>
      </template>

      <template v-slot:cell(options)="data">
        <b-dropdown text="options">
          <b-dropdown-item href="#" @click="openRefreshSegments(data.item)" >Refresh</b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import CodeInput from "vue-verification-code-input"
  import Multiselect from 'vue-multiselect';

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'


export default {
  data() {
    return {
      segments:[],
      offset: 4,
      disabled:false,
      isLoading: false,
      fullPage: true,
      columns:["created","name","totalCustomers","options"],
      pagination: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      }
    };
  },
  components: {
    Loading,
    DatePicker,
    Multiselect
  },
  computed:{
    isActived: function () {
      return this.pagination.current_page + 1;
    },
    pagesNumber: function () {
      if (!this.pagination.to) {
        return [];
      }
      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    }
  },
  mounted (){
    this.getAllCustomerSegmentsData();
  },
  methods: {
    ...mapActions(["getAllCustomerSegments","refreshSegments"]),
    openRefreshSegments(segment){

      this.segment = segment;

      let self = this;
      this.$bvModal.msgBoxConfirm('Refresh segment: '+this.segment.name +'?')
          .then(value => {

            if(value){
              self.confirmRefreshSegments();
            }

          })
          .catch(err => {
            // An error occurred
          })
    },
    confirmRefreshSegments(){

        let self = this;
        this.isLoading = true;

        this.refreshSegments({id: this.segment.id}).then(() => {
          self.isLoading = false;

          self.$bvToast.toast("Segment refreshed", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });

          self.getAllCustomerSegmentsData();

        })
            .catch(error => {

              self.isLoading = false;

              if( typeof error.response.status != "undefined"){
                if(error.response.status == 401){
                  location.replace("/login");
                }

              }
              else{

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });


              }
            })

    },

    getAllCustomerSegmentsData(){
      let self = this;
      this.getAllCustomerSegments()
        .then(function (segments) {
          self.segments = segments;

        })
        .catch(function (error) {

        })
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
